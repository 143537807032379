<template>
    <div>
        <notification-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchNotifReport" />
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageNotification({ page: 1, itemsPerPage: $event })" />
                        <label>entries</label>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="refUserListTable" class="position-relative pb-3" :items="notifLists" responsive :per-page="perPage" :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <!-- Column: Item -->
                <template #cell(type)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar size="32" :src="data.item.notifable_detail.thumbnail" :text="avatarText(data.item.name)" />
                        </template>
                        <div class="font-weight-bold d-block text-nowrap"></div>
                        <small class="text-muted font-weight-bolder">{{ data.item.notifable_type }}</small>
                    </b-media>
                </template>
                <template #cell(created_by)="data">
                    <span class="text-light">
                        {{ data.item.creator.user_name }}
                    </span>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageNotification({ page: $event, itemsPerPage: perPage })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import SpecialItemRepository from "@/abstraction/repository/specialItemRepository";
import useNotificationList from "./useNotificationList";
import userStoreModule from "../user/userStoreModule";
import notificationFilters from "./notificationFilters.vue";
const specialItemRepository = new SpecialItemRepository();
export default {
    components: {
        notificationFilters,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = "app-user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];

        const {
            fetchNotifReport,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            pagination,
            specialItems,
            notifLists,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useNotificationList();
        const blankForm = ref({
            app_section_id: {
                type: "like",
                val: 9,
            },
            featureable_type: {
                type: "like",
                val: null,
            },
            link: {
                type: "like",
                val: null,
            },
        });
        onMounted(() => {
            // fetchSpecialItem({ page: 1, itemsPerPage: perPage.value }, blankForm.value);
            fetchNotifReport({ page: 1, itemsPerPage: perPage.value }).then((res) => {
                console.log(res);
            });
        });

        const changePageNotification = async (paginate) => {
            await fetchNotifReport(paginate);
        };

        return {
            // Sidebar
            specialItems,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            changePageNotification,
            fetchNotifReport,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            avatarText,
            notifLists,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            roleOptions,
            planOptions,
            statusOptions,
        };
    },
    methods: {
        async deleteSpecialItem(specialItemId) {
            await specialItemRepository.delete(specialItemId);
            const index = this.specialItems.findIndex((x) => x.id === specialItemId);
            this.$delete(this.specialItems, index);
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
