<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>Filters</h5>
        </b-card-header>
        <b-card-body>
            <b-row>
                <!--        <b-col-->
                <!--            cols="12"-->
                <!--            md="6"-->
                <!--            class="mb-md-0 mb-2"-->
                <!--        >-->
                <!--          <label>User</label>-->
                <!--          <b-form-input-->
                <!--              id="user"-->
                <!--              v-model="form.user.val"-->
                <!--              class="d-inline-block mr-1"-->
                <!--          />-->
                <!--        </b-col>-->
                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Type</label>
                    <b-form-select id="type" v-model="form.notifable_type.val" class="d-inline-block mr-1">
                        <option value="" selected>All</option>
                        <option value="artist">Artist</option>
                        <option value="music">Music</option>
                        <option value="video">Video</option>
                        <option value="advertise">Advertise</option>
                    </b-form-select>
                </b-col>
                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <b-form-group label="Users" label-for="music">
                        <v-select v-model="form.created_by" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="users" label="name" :clearable="false" input-id="music" @search="filterUser" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="danger" class="mt-1" @click="filterStory"> Filter </b-button>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton, BFormSelect, BFormGroup } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import useStoryList from "@/views/apps/story/useStoryList";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import UserRepository from "@/abstraction/repository/userRepository";
const userRepository = new UserRepository();

export default {
    components: {
        BRow,
        BFormGroup,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        BFormSelect,
        vSelect,
    },
    directives: {
        Ripple,
    },
    props: {
        roleFilter: {
            type: [String, null],
            default: null,
        },
        planFilter: {
            type: [String, null],
            default: null,
        },
        statusFilter: {
            type: [String, null],
            default: null,
        },
        roleOptions: {
            type: Array,
            required: true,
        },
        planOptions: {
            type: Array,
            required: true,
        },
        statusOptions: {
            type: Array,
            required: true,
        },
    },

    setup() {
        const isLoading = ref();
        const {
            fetchStory,
            tableColumns,
            perPage,
            pagination,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            stories,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        } = useStoryList();

        const blankForm = {
            notifable_type: {
                type: "like",
                val: null,
            },
            created_by: {
                type: "like",
                val: null,
            },
        };
        const form = ref(blankForm);

        return {
            form,
            isLoading,
            // Sidebar
            fetchStory,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            stories,
            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        };
    },
    data() {
        return {
            users: undefined,
        };
    },
    methods: {
        filterStory() {
            this.$emit("filter", { page: 1, itemsPerPage: this.perPage }, this.form);
            console.log(this.form);
        },
        filterUser(search) {
            // debouncedFunction(search, async () => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            this.isLoading = true;
            try {
                this.loadUsers(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                this.isLoading = false;
            }
            // });
        },
        async loadUsers(param = {}) {
            const resource = await userRepository.index(param);
            this.users = resource.data;
        },
    },
    created() {
        this.loadUsers();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
